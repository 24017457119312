<template>
  <div class="identify-step3">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="identify-profile-step-three">
        <div class="image__div">
          <img src="~@/assets/image/Group1757.svg" />
        </div>
        <p class="f-w6">証明書の提出が完了しました。</p>
        <div class="f-w3 note">
          <p class="note--top">
            通常15分～24時間程度で 確認作業が完了いたします。
          </p>
          <p class="note--bottom">
            完了次第、アプリ内メッセージおよびメールにお知らせいたします。
          </p>
        </div>
        <button class="f-w3 mb-3" type="submit" @click="returnMainScreen()">
          ホーム画面に戻る
        </button>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader.vue";
import CardFooter from "@/components/CardFooter.vue";
export default {
  name: "IdentifyProfileStepthree",
  components: {
    CardHeader,
    CardFooter
  },
  data() {
    return {
      headerTitle: {
        text: "身分証明書の登録",
        isShowTitle: true,
        isShowLogo: false
      }
    };
  },
  methods: {
    returnMainScreen() {
      const user = this.$store.getters["auth/user"];
      if (user.sex == 1) {
        this.$router.push({
          name: "Home"
        });
      } else
        this.$router.push({
          name: "CastHome"
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_fontFamily.scss";
@import "~@/assets/scss/cast/identifyProfileStepThree.scss";

.content-scroll {
  // overflow-y: scroll;
  padding-bottom: 120px;
  padding-top: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 152px);
  // }
}
</style>
